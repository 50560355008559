export enum PageTitlePositioning {
	/**
	 * append page title after application name
	 */
	AppendPageTitle = 0,
	/**
	 * prepend page title before application name
	 */
	PrependPageTitle = 10
}
