// module
import { MetaSettings } from './models/meta-settings';
import { PageTitlePositioning } from './models/page-title-positioning';

export abstract class MetaLoader {
	abstract get settings(): MetaSettings;
	abstract setSettings(newSettings): void;
}

export class MetaStaticLoader implements MetaLoader {
	private metaSettings: MetaSettings;
	get settings(): MetaSettings {
		return this.metaSettings;
	}

	setSettings(newSettings: MetaSettings) {
		this.metaSettings = {
			...this.metaSettings,
			...newSettings
		};
	}

	constructor(
		private readonly providedSettings: MetaSettings = {
			pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
			defaults: {}
		}
	) {
		this.metaSettings = this.providedSettings;
	}
}
