// angular
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
// 3rd party
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from '@nx-bundesliga/ngx-auto-unsubscribe-decorator';
// libs
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Competition } from '@nx-bundesliga/models';
import { BUNDESLIGA_SEASONNAMES } from '@nx-bundesliga/models';
import { getWorkingCompetition } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { MetaLoader } from './meta.loader';

// module

@Injectable()
export class MetaService {
	private readonly isMetaTagSet: any;
	@AutoUnsubscribe() private titleSubscription: Subscription = new Subscription();
	@AutoUnsubscribe() private tagSubscription: Subscription = new Subscription();

	constructor(readonly loader: MetaLoader, private title: Title, private readonly meta: Meta, private readonly cstore: Store<Competition>, private configService: ConfigService, private translate: TranslateService) {
		this.isMetaTagSet = {};
	}

	setTitle(title: string | { value: string; interpolateParams?: Object }, prepend = true): void {
		const subscription = (title ? this.callback(title) : of(''))
			.pipe(
				switchMap((res: string) => (!res && this.loader?.settings?.defaults?.title ? this.callback(this.loader.settings.defaults['title']) : of(res))),
				switchMap((res: string) => this.getTitleWithPositioning(res, prepend)),
				take(1)
			)
			.subscribe({
				next: (titleWithPosition: string) => {
					this.updateTitle(titleWithPosition);
				}
			});
		this.titleSubscription.add(subscription);
	}

	setTag(key: string, value: string | { value: string; interpolateParams?: Object }): void {
		if (key === 'title') {
			throw new Error(`Attempt to set ${key} through "setTag": "title" is a reserved tag name. ` + 'Please use `MetaService.setTitle` instead.');
		}

		value = value || (this.loader.settings.defaults && this.loader.settings.defaults[key] ? this.loader.settings.defaults[key] : '');

		const value$ = key !== 'og:locale' && key !== 'og:locale:alternate' ? this.callback(value) : of(value);

		const subscription = value$.pipe(take(1)).subscribe((res: string) => {
			this.updateTag(key, res);
		});
		this.tagSubscription.add(subscription);
	}

	update(currentUrl: string, metaSettings?: any): void {
		if (!metaSettings?.title) {
			const fallbackTitle = this.loader.settings.defaults ? this.loader.settings.defaults['title'] || this.loader.settings['applicationName'] : this.loader.settings['applicationName'];
			this.setTitle(fallbackTitle, true);
		} else {
			if (metaSettings.disabled) {
				this.update(currentUrl);
				return;
			}

			this.setTitle(metaSettings.title, metaSettings.override);
			Object.keys(metaSettings).forEach((key) => {
				if (key === 'title' || key === 'override') {
					return;
				} else if (key === 'og:locale:alternate') {
					const currentLocale = metaSettings['og:locale'];
					this.updateLocales(currentLocale, metaSettings[key]);
					return;
				}

				this.setTag(key, metaSettings[key]);
			});
		}

		if (this.loader.settings.defaults) {
			Object.keys(this.loader.settings.defaults).forEach((key) => {
				const value = this.loader.settings.defaults[key];

				if ((metaSettings && (key in this.isMetaTagSet || key in metaSettings)) || key === 'title' || key === 'override') {
					return;
				} else if (key === 'og:locale:alternate') {
					const currentLocale = metaSettings ? metaSettings['og:locale'] : undefined;
					this.updateLocales(currentLocale, value);

					return;
				}

				this.setTag(key, {
					value: value,
					interpolateParams: {
						season: this.configService.getSettings('DFL-COM-000001.season.name', BUNDESLIGA_SEASONNAMES.CURRENT)
					}
				});
			});
		}

		const url = ((this.loader.settings.applicationUrl || '/') + currentUrl).replace(/(https?:\/\/)|(\/)+/g, '$1$2').replace(/\/$/g, '');

		this.setTag('og:url', url || '/');
	}

	removeTag(key: string): void {
		this.meta.removeTag(key);
	}

	private callback(value: string | { value: string; interpolateParams?: Object }): Observable<string> {
		if (typeof value === 'string') {
			return value !== '' ? this.translate.get(value) : of('');
		} else {
			return this.translate.get(value.value, value.interpolateParams || {});
		}
	}

	private getTitleWithPositioning(title: string, prepend: boolean): Observable<string> {
		return this.cstore.pipe(select(getWorkingCompetition)).pipe(
			switchMap((comp: Competition) => {
				return this.translate.get('COMPETITION.' + comp.name + '.NAME').pipe(map((competitionAppTitle) => ({ competitionAppTitle, comp })));
			}),
			map(({ competitionAppTitle, comp }) => {
				const seperator = this.loader.settings.pageTitleSeparator;
				switch (prepend) {
					case true:
						return title && title.trim() !== '' ? competitionAppTitle + seperator + title : competitionAppTitle;
					case false:
						return title && title.trim() !== '' ? title + seperator + competitionAppTitle : competitionAppTitle;
					default:
						return competitionAppTitle;
				}
			})
		);
	}

	private updateTitle(title: string): void {
		this.title.setTitle(title);
		this.meta.updateTag({
			property: 'og:title',
			content: title
		});
	}

	private updateLocales(currentLocale: string, availableLocales: string): void {
		currentLocale = currentLocale || (this.loader.settings.defaults ? this.loader.settings.defaults['og:locale'] : '');

		if (currentLocale && this.loader.settings.defaults) {
			this.loader.settings.defaults['og:locale'] = currentLocale;
		}

		// TODO: set HTML lang attribute - https://github.com/ngx-meta/core/issues/32
		// const html = this.document.querySelector('html');
		// html.setAttribute('lang', currentLocale);

		const elements = this.meta.getTags('property="og:locale:alternate"');

		elements.forEach((element: any) => {
			this.meta.removeTagElement(element);
		});

		if (currentLocale && availableLocales) {
			availableLocales.split(',').forEach((locale: string) => {
				if (currentLocale !== locale) {
					this.meta.addTag({
						property: 'og:locale:alternate',
						content: locale
					});
				}
			});
		}
	}

	private getValue(value: any): string {
		if (value.hasOwnProperty('value')) {
			return this.getValue(value.value);
		}

		return value;
	}

	public updateTag(key: string, value: string): void {
		if (key.lastIndexOf('og:', 0) === 0) {
			let content = this.getValue(value);
			if (key === 'og:locale') {
				content = content.replace(/-/g, '_');
			}

			this.meta.updateTag({
				property: key,
				content
			});
		} else {
			this.meta.updateTag({
				name: key,
				content: value
			});
		}

		this.isMetaTagSet[key] = true;

		if (key === 'description') {
			this.meta.updateTag({
				property: 'og:description',
				content: value
			});
		} else if (key === 'author') {
			this.meta.updateTag({
				property: 'og:author',
				content: value
			});
		} else if (key === 'publisher') {
			this.meta.updateTag({
				property: 'og:publisher',
				content: value
			});
		} else if (key === 'og:locale') {
			const availableLocales = this.loader.settings.defaults ? this.loader.settings.defaults['og:locale:alternate'] : '';

			this.updateLocales(value, availableLocales);
			this.isMetaTagSet['og:locale:alternate'] = true;
		} else if (key === 'og:locale:alternate') {
			const currentLocale = this.meta.getTag('property="og:locale"').content;

			this.updateLocales(currentLocale, value);
			this.isMetaTagSet['og:locale'] = true;
		}
	}
}
