// angular
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
// module
import { MetaGuard } from './lib/meta.guard';
import { MetaLoader, MetaStaticLoader } from './lib/meta.loader';
import { MetaService } from './lib/meta.service';

export * from './lib/models/meta-settings';
export * from './lib/models/page-title-positioning';
export * from './lib/meta.guard';
export * from './lib/meta.loader';
export * from './lib/meta.service';

// for AoT compilation
export function metaFactory(): MetaLoader {
	return new MetaStaticLoader();
}

@NgModule()
export class MetaModule {
	static forRoot(
		configuredProvider: any = {
			provide: MetaLoader,
			useFactory: metaFactory
		}
	): ModuleWithProviders<MetaModule> {
		return {
			ngModule: MetaModule,
			providers: [configuredProvider, MetaGuard, MetaService]
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: MetaModule) {
		if (parentModule) {
			throw new Error('MetaModule already loaded; import in root module only.');
		}
	}
}
